<template>
  <div
    class="dropdown"
    :class="collapsed && 'collapsed'"
    :style="`
      --maxHeight: ${maxHeight};
    `"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    collapsed: {
      type: Boolean,
      default: true
    },
    maxHeight: {
      type: String,
      default: '70vh'
    }
  }
}
</script>

<style lang="stylus" scoped>
.dropdown
  overflow-y scroll
  transition max-height 0.7s ease-in-out
  /** Hide scrollbars */
  max-height var(--maxHeight)
  -ms-overflow-style none
  scrollbar-width none
  &::-webkit-scrollbar
    display none
  &.collapsed
    max-height 0
</style>
